<template>
  <ApLoading v-if="isLoading" class="mt-10" text="Loading Rule..." />

  <RulesStatus
    v-else-if="isError"
    class="my-10"
    status-icon="$mdi-alert-circle-outline"
    title="CleanData Server Message"
    description="CleanData is hard at work analyzing data to identify and fix data quality issues."
    button-icon="$mdi-refresh"
    button-label="Try again!"
    @click="getRules"
  />

  <div
    v-else
    class="d-flex flex-column"
    style="max-height: 100vh; height: 100%"
  >
    <div class="d-flex justify-space-between align-center text-h4 my-4 px-6">
      <v-btn text class="mr-2" @click="$router.go(-1)">
        <v-icon left> $mdi-arrow-left </v-icon>
        Go back
      </v-btn>

      <div class="text-center">
        {{ directorRule.name }}
      </div>

      <v-btn
        color="primary"
        :loading="isSavingSomeRule"
        @click="saveCurrentRule"
      >
        <v-icon left> $mdi-check</v-icon>
        Save
      </v-btn>
    </div>

    <v-divider />

    <v-stepper
      v-if="tabs && tabs.length > 0"
      v-model="currentStep"
      flat
      elevation="0"
      class="d-flex flex-column pa-0"
      style="flex-grow: 1"
    >
      <div>
        <v-stepper-header
          style="box-shadow: none; max-width: 1200px; width: 100%"
          class="d-flex justify-center mx-auto"
        >
          <template v-for="(tab, index) in tabs">
            <v-stepper-step
              :key="index"
              :editable="directorRulesHasAllRules"
              :step="index + 1"
            >
              <div>{{ tab.title }}</div>
              <!-- <small class="font-weight-medium ap-red--text"> Error </small> -->
            </v-stepper-step>

            <v-divider
              v-if="index < tabs.length - 1"
              :key="`divider_${index}`"
            />
          </template>
        </v-stepper-header>
      </div>

      <v-divider style="width: 960px; align-self: center" />

      <v-stepper-items style="flex-grow: 1; overflow-y: scroll">
        <v-stepper-content
          v-for="(tab, index) in tabs"
          :key="`${index}-content`"
          :step="index + 1"
          class="pa-0"
        >
          <component
            :is="tab.component"
            v-if="currentStep === index + 1"
            class="mx-auto"
            @saved="currentStep = currentStep + 1"
          />
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </div>
</template>

<script>
import ApLoading from '@/components/common/ApLoading'
import RulesStatus from './components/business-rules/RulesStatus'
import { mapActions, mapState } from 'vuex'
import { RULE_TYPES } from '@/api/business-rules/rules'

export default {
  components: {
    ApLoading,
    RulesStatus,
    MatchingCriteriaRule: () =>
      import('./components/business-rules/MatchingCriteriaRule'),
    MatchingScore: () => import('./components/business-rules/MatchingScore'),
    SegmentationRule: () =>
      import('./components/business-rules/SegmentationRule'),
    RollUpRule: () => import('./components/business-rules/RollUpRule'),
    AssignmentRule: () => import('./components/business-rules/AssignmentRule'),
    PrimaryRule: () => import('./components/business-rules/PrimaryRule'),
    WriteRule: () => import('./components/business-rules/WriteRule'),
    RuleSummary: () => import('./components/business-rules/RuleSummary'),
  },
  data() {
    return {
      isLoading: false,
      isError: false,
      currentStep: 1,
      tabs: [
        {
          name: RULE_TYPES.segmentation,
          title: 'Segmentation',
          component: 'SegmentationRule',
        },
        {
          name: RULE_TYPES.matchCriteria,
          title: 'Matching',
          component: 'MatchingCriteriaRule',
        },
        {
          name: RULE_TYPES.matchCriteria,
          title: 'Matching Score',
          component: 'MatchingScore',
        },
        {
          name: RULE_TYPES.assignment,
          title: 'Assignment',
          component: 'AssignmentRule',
        },
        {
          name: RULE_TYPES.primary,
          title: 'Primary',
          component: 'PrimaryRule',
        },
        {
          name: RULE_TYPES.rollUp,
          title: 'Roll Up',
          component: 'RollUpRule',
        },
        {
          name: RULE_TYPES.write,
          title: 'Write',
          component: 'WriteRule',
        },
        {
          name: 'summary',
          title: 'Summary',
          component: 'RuleSummary',
        },
      ],
    }
  },
  computed: {
    ...mapState('business-rules', {
      isSavingSomeRule: (state) =>
        Object.keys(state.directorRule?.rules).findIndex(
          (key) => state.directorRule.rules[key].isTriggeringSave
        ) > -1,
      directorRule: (state) => state.directorRule,
    }),
    directorRulesHasAllRules() {
      return Object.entries(this.directorRule.rules).length === 8
    },
  },
  created() {
    this.getRules()
  },
  methods: {
    ...mapActions('business-rules', [
      'getRule',
      'getDirectorRule',
      'triggerSaveCurrentRule',
    ]),
    async getRules() {
      this.isLoading = true
      this.isError = false

      try {
        await this.getDirectorRule(this.$route.params.directorRuleId)
        await this.getRule(this.directorRule.rules.MultiMap.id)
      } catch {
        this.isError = true
      } finally {
        this.isLoading = false
      }
    },
    saveCurrentRule() {
      this.triggerSaveCurrentRule(this.tabs[this.currentStep - 1].name)
    },
  },
}
</script>
